const Config = {
  api: {
    apiType: window.env.apiType,
    authType: window.env.authType,
    host: window.env.host,
    authHost: window.env.authHost,
    // TODO: remove cswUrl after api is ready
    cswUrl: window.env.cswUrl,
    cswFrontUrl: window.env.cswFrontUrl,
    authClientId: window.env.authClientId,
    authClientSecret: window.env.authClientSecret,
    headers: {
      // 'Pragma': 'no-cache',
      // 'Cache-control': 'no-cache'
    },
  },
  appOptions: {
    version: window.env.version,
    standType: window.env.standType,
  },
  services: {
    sentryDsn: window.env.sentryDsn,
    yandexMetrika: {
      account: window.env.yandexMetricAccount,
      options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      },
    },
  },
  isShowUploadStatistics: window.env.uploadStatisitcs,
};

export default Config;
