import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { ScrollPanel } from 'primereact/scrollpanel';
import { localize } from 'shared/components/other';
import { IColorTheme } from 'types';
import { IBrand } from 'types/brand';
import { ICampaign } from 'types/campaign';
import { Footer } from 'shared/components/layout/Footer';
import { Sorter, SorterProps } from 'shared/components/common/misc/Sorter';
import { CampaignCard } from 'shared/components/domain/CampaignCard';
import {
  Paginator,
  PageLinksWithCurrentPageReport,
  RowsPerPageSelector,
  PaginatorProps,
} from 'shared/components/common/misc/Paginator';
import { Button } from 'shared/components/common/buttons/Button';
import { CampaignFilters, CampaignFiltersProps } from '../CampaignFilters';
import classes from './Campaigns.module.scss';

type Props = {
  brands: IBrand[];
  cardsTheme: IColorTheme;
  className?: string;
  data: ICampaign[];
  filter: CampaignFiltersProps;
  isAddButtonVisible: boolean;
  locale: string;
  onFavoriteButtonClick(value: ICampaign): void;
  paginator: PaginatorProps;
  sorter: SorterProps;
  totalRecords: number;
};

export const Campaigns: React.VFC<Props> = React.memo((props) => {
  const {
    brands,
    cardsTheme,
    className,
    data,
    filter,
    isAddButtonVisible,
    locale,
    onFavoriteButtonClick,
    paginator,
    sorter,
    totalRecords,
  } = props;

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.left}>
        <h1 className={classes.title}>{localize('site.title.campaigns')}</h1>
        <CampaignFilters className={classes.filters} {...filter} />
        <Footer className={classes.footer} />
      </div>
      <div className={classes.right}>
        {isAddButtonVisible && (
          <Link className={classes.addCampaign} to="/campaigns/new" data-test="add-campaign-button">
            <Button label={localize('campaigns.add-campaign')} icon="pi pi-plus" />
          </Link>
        )}
        <div className={classes.pagination}>
          <span className={classes.total}>
            <span className={classes.totalLabel}>{localize('campaigns.campaigns-total')}: </span>
            <span className={classes.totalValue}>{totalRecords}</span>
          </span>
          <Paginator
            className={classes.paginator}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 25, 50, 100]}
            {...paginator}
          >
            <RowsPerPageSelector title={`${localize('campaigns.page-size')}: `} />
            {data.length > 0 && <PageLinksWithCurrentPageReport />}
            <Sorter className={classes.sorter} {...sorter} />
          </Paginator>
        </div>
        <ScrollPanel className={classes.list}>
          {data.length
            ? data.map((x) => (
                <Link
                  key={x.id}
                  className={classes.campaignLink}
                  to={`/campaigns/${x.id}`}
                  data-test={`campaign-card-${x.id}`}
                >
                  <CampaignCard
                    brands={brands}
                    campaign={x}
                    locale={locale}
                    onFavoriteButtonClick={() => onFavoriteButtonClick(x)}
                    theme={cardsTheme}
                  />
                </Link>
              ))
            : localize('messages.nothing-found')}
        </ScrollPanel>
      </div>
    </div>
  );
});

export type { Props as CampaignsProps };
