import { emptyPivotData } from 'routes/reportConstructor/stores/helpers/overridePivot/config';
import {
  AggregatedTableData,
  AggregatedTreeData,
  CellContent,
  ColumnOption,
  PivotStructure,
  TreeData,
  TreeDataItem,
} from 'types/reportConstructor';
import { getNumberFormat } from 'routes/reportConstructor/stores/helpers';
import { REPORT_PAGE_SIZE } from '../reportConstructor';
import {
  getDataTypesAndAmounts,
  getFormattedValueFunc,
  getNewValues,
  getValuesRow,
  makeDataCategories,
} from './helpers';
import { buildHeader, buildTreeAndReturnData, AddToHeaderFuncParams } from './builders';

export const convertForCustomBackend = (
  data: CellContent[][],
  structure: PivotStructure,
  allColumns: ColumnOption[],
) => {
  const { tree, table } = JSON.parse(JSON.stringify(emptyPivotData)) as {
    tree: AggregatedTreeData;
    table: AggregatedTableData;
  };

  if (!structure.rows.length) return null;

  const typesAndAmount = getDataTypesAndAmounts(data[0], structure, allColumns);

  if (!data.length || !typesAndAmount) return null;

  const { types, amount } = typesAndAmount;
  const valuesData = getValuesRow(structure, allColumns);
  const valuesAmount = structure.values.length;

  if (!valuesAmount) return null;

  const slicesData = data.slice(0, REPORT_PAGE_SIZE);

  // need to:
  // don't repeat columns and rows
  // make values in right cells
  const addedColumns: { label: string; void: number }[] = [];
  const addedRows: {
    label: string;
    link: TreeDataItem<TreeData>;
    length: { tree: number; table: number };
    lastId: number;
  }[] = [];
  // prepare tree and table
  if (structure.columns.length) {
    tree.header.data.push(...structure.columns.map((_, index) => [{ text: '', rowspan: amount.columns + 1 - index }]));

    table.header.data.push(
      ...structure.columns.map((_, index) =>
        structure.rows.map((row) => ({ text: row, rowspan: amount.columns + 1 - index })),
      ),
    );
  }

  tree.header.data.push([null]);
  table.header.data.push([...structure.rows.map((row) => row)]);

  tree.header.nonEmpty.push(0);
  structure.rows.forEach(() =>
    table.header.nonEmpty.push(
      table.header.nonEmpty.length ? table.header.nonEmpty[table.header.nonEmpty.length - 1] + 1 : 0,
    ),
  );

  tree.header.meta.push({});
  structure.rows.forEach(() => table.header.meta.push({}));

  slicesData.forEach((contentRow, index) => {
    const { rows: rowsData, columns: columnsData } = makeDataCategories(contentRow, amount);

    const rows = rowsData.map(getFormattedValueFunc(types.rows));
    const columns = columnsData.map(getFormattedValueFunc(types.columns));
    const values = contentRow.slice(-valuesAmount).map((value) => String(getNumberFormat(value)));

    const columnsRow = columns.join(',');
    const isColumnsAdded = addedColumns.some(({ label }) => label === columnsRow);
    const needToAddValues = !isColumnsAdded || !columns.length;
    if (needToAddValues) {
      addedColumns.push({
        label: columnsRow,
        void: !addedColumns.length ? 0 : addedColumns[addedColumns.length - 1].void + valuesAmount,
      });
    }

    const treeHeaderParams: AddToHeaderFuncParams = {
      valuesData,
      header: tree.header,
    };

    if (needToAddValues && columns.length) {
      treeHeaderParams.columns = [...columns];
      treeHeaderParams.columnAmount = columns.length;
    }

    if (needToAddValues && (columns.length || index === 0)) {
      buildHeader(treeHeaderParams);
      buildHeader({ ...treeHeaderParams, columns: [...columns], header: table.header });
    }

    const rowsRow = rows.join(',');
    const isRowsAdded = addedRows.some(({ label }) => label === rowsRow);
    if (!isRowsAdded) {
      const { link, lastId, length } = buildTreeAndReturnData(
        tree.result.tree,
        [...rows],
        addedRows.length ? addedRows[addedRows.length - 1].lastId : 0,
      );
      tree.result.data.push([rows[rows.length - 1]]);
      table.result.data.push(rows);

      addedRows.push({ link, lastId, length: { tree: length, table: 0 }, label: rowsRow });
    }

    const addedColumn = addedColumns.find(({ label }) => label === columnsRow);
    const addedRow = addedRows.find(({ label }) => label === rowsRow);
    const indexOfAddedRow = addedRow ? addedRows.indexOf(addedRow) : -1;

    if (addedColumn && addedRow && indexOfAddedRow >= 0) {
      addedRow.link.values = getNewValues(addedRow.link.values, values, addedColumn.void);
      addedRow.length.tree = addedRow.link.values.length;

      table.result.data[indexOfAddedRow] = getNewValues(table.result.data[indexOfAddedRow], values, addedColumn.void);
      addedRow.length.table = table.result.data[indexOfAddedRow].length;
    }
  });

  return { data: { tree, table }, hasNextPage: data.length > REPORT_PAGE_SIZE };
};
