import React from 'react';
import cx from 'classnames';
import { Select, SelectProps } from 'shared/components/common/form/Select';
import classes from './Header.module.scss';
import csLogoUrl from './assets/cs-logo.png';
import skLogoUrl from './assets/sk-logo.svg';
import { LinkIcons, LinkIconsProps } from './components/LinkIcons';
import { NavMenu, NavMenuProps } from './components/NavMenu';
import { User, UserProps } from './components/User';

type Props = {
  className?: string;
  info: SelectorType | React.ReactNode;
  linkIcons: LinkIconsProps;
  navMenu: NavMenuProps;
  user: UserProps;
};

type SelectorType = SelectProps & { type: 'selector' };

const isSelector = (info: SelectorType | React.ReactNode): info is SelectorType =>
  !!(info && typeof info === 'object' && 'type' in info && info.type === 'selector');

export const Header: React.VFC<Props> = React.memo((props) => {
  const { className, info, navMenu, linkIcons, user } = props;
  return (
    <header className={cx(classes.root, className)}>
      <img className={classes.logo} src={csLogoUrl} alt="Aizek logo" />
      <div className={classes.info} data-test="info">
        {isSelector(info) ? <Select {...info} className={cx(classes.dropdown, info.className)} /> : info}
      </div>
      <NavMenu className={classes.navMenu} {...navMenu} />
      <div className={classes.user}>
        <User {...user} />
      </div>
      <LinkIcons className={classes.linkIcons} {...linkIcons} />
      <a className={classes.skLink} href="https://sk.ru/" target="_blank" rel="noopener noreferrer">
        <img className={classes.skLogo} src={skLogoUrl} alt="SK Resident" />
      </a>
    </header>
  );
});

export type { Props as HeaderProps };
