import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Routes from 'routes';
import cx from 'classnames';

import { useStores } from 'core';
import { usePrevious } from 'helpers';
import { FailedFetchNotification, localize, Scrollbar } from 'shared/components/other';
import { Header } from 'features/Header';
import { ConfirmDialog } from 'features/ConfirmDialog';
import { Sidebar } from 'features/Sidebar';
import { GlobalTooltip } from 'shared/components/common/misc/GlobalTooltip';
import { Loader } from 'shared/components/common/misc/Loader';
import { toast, Toast } from 'shared/components/common/misc/Toast';
import classes from './App.module.scss';

const App: React.FC = observer(() => {
  const { pathname, state } = useLocation<{ from?: { pathname: string } }>();
  const isPromoPages = pathname === '/' || pathname === '/market-analytics' || pathname === '/price-list';
  const isDashboardPage = pathname.includes('/dashboard/');
  const { authStore, advertiserStore, globalLoaderStore } = useStores();
  const { isAuthorized, getAccessToken, getRefreshToken } = authStore;
  const {
    resetAdvertiser,
    selectAdvertiser,
    loadAdvertisers,
    resetAdvertisers,
    advertisers,
    currentAdvertiserId,
  } = advertiserStore;

  const hasHeader = !isPromoPages && !pathname.includes('/auth') && isAuthorized;

  const prevIsAuthorized = usePrevious(isAuthorized);
  useEffect(() => {
    if (!prevIsAuthorized && isAuthorized) {
      loadAdvertisers();
    } else if (prevIsAuthorized && !isAuthorized) {
      resetAdvertisers();
    }
  }, [isAuthorized, prevIsAuthorized, loadAdvertisers, resetAdvertisers]);

  const prevAdvertisers = usePrevious(advertisers);
  useEffect(() => {
    if (!prevAdvertisers?.length && advertisers.length) {
      const foundAdvertiser = advertisers.find((a) => a.id === currentAdvertiserId);
      if (currentAdvertiserId && foundAdvertiser) {
        selectAdvertiser(currentAdvertiserId);
      } else if (advertisers.length) {
        selectAdvertiser(advertisers[0].id, Boolean(currentAdvertiserId));
      } else {
        resetAdvertiser();
      }
    }
  }, [advertisers, currentAdvertiserId, prevAdvertisers, resetAdvertiser, selectAdvertiser]);

  useEffect(() => {
    if (pathname === '/auth') {
      toast.dismiss();
      toast.clearWaitingQueue();
      const prevPathname = state?.from?.pathname;
      const hasNoTokens = !getAccessToken() && !getRefreshToken();
      if (prevPathname && hasNoTokens) {
        toast.info(localize('messages.info.login-to-access'));
      }
    }
  }, [pathname, state?.from?.pathname, getAccessToken, getRefreshToken]);

  return (
    <div className={classes.Root}>
      <Helmet titleTemplate="%s - Aizek Client Space" defaultTitle="Aizek Client Space" />
      {!isDashboardPage ? (
        <Scrollbar
          viewProps={{ id: 'main_content' }}
          className={cx(classes.Scrollbar, {
            [classes.ScrollbarResponsive]: isPromoPages,
          })}
          hasHeader={isAuthorized}
        >
          {hasHeader && <Header />}
          <main className={cx(classes.Main, { [classes.MainWithHeaderOffset]: hasHeader })}>
            <Routes />
          </main>
        </Scrollbar>
      ) : (
        <main className={classes.Main}>
          <Routes />
        </main>
      )}
      <FailedFetchNotification />
      {/* <YandexMetrika /> */}
      <Loader fullscreen size="large" visible={globalLoaderStore.isLoading} withBackground />
      <ConfirmDialog />
      <Sidebar />
      <GlobalTooltip adjustable mouseTrackStatic />
      <Toast />
    </div>
  );
});

export default App;
