import React from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import Config from 'config';
import classes from './Footer.module.scss';

type Props = {
  className?: string;
  version?: string;
  copyright?: string;
  color?: 'dark' | 'light';
};

const currentYear = new Date().getFullYear();

export const Footer: React.FC<Props> = (props) => {
  const {
    className,
    version = Config.appOptions.version,
    copyright = localize('site.footer.copyright'),
    color = 'dark',
  } = props;

  return (
    <div className={cx(classes.root, className)}>
      <div
        className={cx(classes.content, {
          [classes.colorLight]: color === 'light',
        })}
      >
        <span className={classes.year}>{currentYear}</span>
        <span className={classes.copyright}>{copyright}</span>
        <span className={classes.version}>{version}</span>
      </div>
    </div>
  );
};

export type { Props as FooterProps };
